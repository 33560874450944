import React from 'react';
import { Outlet } from 'react-router-dom';
import './mainApp.css';

function MainApp() {
    return (
        <div className="MainApp">
            <div className="MainAppContHolder">
                <Outlet />
            </div>
        </div>
    );
}

export default MainApp;
