import React from 'react';
import './featureSection.css';
import pointer from '../../images/pointer-loc.svg';

function FeatureSection({featurs}) {
    return (
        <div className="feature-section-main">
            <h3 className="feature-section-header">Product Features</h3>
            <div className="feature-section-list-holder">
                {
                    featurs.map((fer) => (
                        feat(fer)
                    ))
                }
            </div>
        </div>
    );
}

const feat = (nameIt) => {
    return (
        <div className="componet-section-list-item">
            <div className="component-section-item-point">
                <img src={pointer} alt="pointer" className="component-section-item-point-pointer" />
            </div>
            <div className="component-section-item-name">
                {
                    nameIt
                }
            </div>
        </div>
    )
}

export default FeatureSection;
