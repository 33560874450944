import React from 'react';
import './mount.css';
// static
import sideProfile from '../../images/screen-mount.png';
import thinSide from '../../images/side-thin-profile.png';

function Mount() {
    return (
        <div className="Mount-Section-Home">
            <div className="sub-Mount-Section-Home">
                <div className="Mount-Head-holder">
                    <h1 className="be-vietnam-pro-extrabold Mount-section-Main-Header">
                        Mount Anywhere,
                    </h1>
                </div>
                <div className="Mount-image-holder-sect-1">
                    <img src={sideProfile} className="image-side-screen-profile" alt="side-screen-profile" />
                </div>
            </div>
            <div className="side-profile-mount-section-home">
                <div className="side-profile-box-thing-home">
                    <h2 className="side-profile-header-mount-home">
                        <span style={{color: "#04A79E"}}>All</span> Around<br/>Thin & Durable Design
                    </h2>
                    <img src={thinSide} className="side-profile-image-screen" alt="screen sides showing thickness" />
                </div>
            </div>
        </div>
    );
}

export default Mount;