import React from 'react';
import PropTypes from 'prop-types';
import './educationRow.css';
// statics
import che from '../../images/check.svg';

function EducationRow({
    header,
    logoz,
    descriptions,
    image
}) {
    return (
        <div className="education-row">
            <div className="education-row-content-holder">
                <div className="education-row-image-content">
                    <img src={image} alt="description" className="education-row-image-img" />
                </div>
                <div className="education-row-description-side">
                    <h2 className="education-row-description-header">
                        {
                            header
                        }
                    </h2>
                    <div className="education-row-description-icons-holder">
                        <img src={logoz} alt="icons" className="education-row-description-icons" />
                    </div>
                    <div className="education-row-description-list-holder">
                        {
                            descriptions.map((disc) => (
                                <div className="education-row-description-list-item">
                                    <div className="education-row-description-list-item-icon-holder">
                                        <img src={che} alt="check icon" className="education-row-description-list-item-icon" />
                                    </div>
                                    <div className="education-row-description-list-item-text">
                                        {
                                            disc
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

EducationRow.propTypes = {
    header: PropTypes.string.isRequired,
    logoz: PropTypes.string.isRequired,
    descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.string.isRequired
}

export default EducationRow;
