import React from 'react';
import './sizeSection.css';

function SizeSection({sizes, active, actFun}) {
    return (
        <div className="size-section-main">
            <h3 className="size-section-header">Screen Sizes</h3>
            <div className="sizes-section-holder">
                {
                    sizes.map((size, indx) => (
                        <button type="button" style={active === indx ? {border : "1px solid #04A79E"} : {}} onClick={()=> actFun(indx)} className="sizes-section-size-holder">
                            {
                                size
                            }
                        </button>
                    ))
                }
                
            </div>
        </div>
    );
}

export default SizeSection;
