import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './productRow.css'
import _ from 'lodash';
import Popup from '../../popup/Popup';

function ProductRow({
    image, header, descri, funact, productId, arrg, colors, prList
}) {
    const [popup, setPopup] = useState(false);
    const actionFun = (id) => {
        if (descri === "list") {
            setPopup(true)
            return
        }
        funact(id);
    }
    return (
        <div className="product-row-main" style={arrg ?{flexDirection: "row-reverse"} : {}}>
            <div className={`product-row-image-div ${colors}`}>
                <img src={image} className="product-row-image" alt={header} />
            </div>
            <div className="product-row-description-div">
                <h3 className="product-row-description-header be-vietnam-pro-black">
                    {
                        header
                    }
                </h3>
                <div className="product-row-description-text">
                    <h3 className="product-row-description-header-features" style={descri === 'list' ? {}: {display: "none"}}>
                        Features
                    </h3>
                    {
                        descri === 'list' && _.isArray(prList) && !_.isEmpty(prList)?
                        prList.map((val) => (
                            <div className="product-row-list-item-row">
                                <div className="product-row-list-item-icon">
                                    <span className="material-symbols-outlined">
                                        location_searching
                                    </span>
                                </div>
                                <div className="product-row-list-item-text">
                                    {
                                        val
                                    }
                                </div>
                            </div>
                        ))
                        : descri
                    }
                </div>
                <div className="product-row-description-button-div">
                    <button className="product-row-description-button" type="button"  onClick={()=> actionFun(productId)}>
                        {
                            descri === 'list' ? 'Order Now' : 'View More'
                        }
                    </button>
                </div>
            </div>
            <div className="product-row-popup-holder" style={popup ? {} : { display: "none"}}>
                <Popup
                    closeFun={setPopup}
                    dataFun={{
                        ProductName: header
                    }}
                />
            </div>
        </div>
    );
}


ProductRow.propTypes = {
    image: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    descri: PropTypes.string.isRequired,
    funact: PropTypes.func.isRequired,
    productId: PropTypes.string.isRequired,
    arrg: PropTypes.bool.isRequired,
    prList: PropTypes.arrayOf(PropTypes.string)
};

export default ProductRow;
