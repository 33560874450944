import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './productPage.css';
// statics
import { productsList } from '../ProductList';
import pointer from '../images/pointer-loc.svg';
import imgIcon from '../images/photo_library.svg';
// static components
import ComponentSection from './componets/ComponentSection';
import SizeSection from './sizes/SizeSection';
import FeatureSection from './features/FeatureSection';
import _ from 'lodash';
import GalleryImage from './ProductGallery/GalleryImage';
import Popup from '../Home/popup/Popup';

function ProductPage() {
    const navigate = useNavigate();
    const [photos, setPhotos] = useState('closed');
    const [popup, setPopup] = useState(false);
    const [aPrice, setAprice] = useState(0)
    let {id} = useParams();
    const prod = productsList[id];
    if(typeof (prod) === "undefined") {
        return (<div>No Data found</div>);
    }
    return (
        <div className="product-page-main">
            <div className="product-page-cont-Holder">
                <h1 className="product-page-header-name">
                    {
                        prod.name
                    }
                </h1>
                <div className="product-page-image-description">
                    <div className="product-page-image-side">
                        <div className="product-page-image-holder">
                            <img src={prod.mainImage} className="product-page-image" alt="product" />
                            <button type="button" className="product-page-view-imags-button" onClick={()=> setPhotos('open')}>
                                <img src={imgIcon} className="product-page-view-imags-button-icon" alt="icon" />
                            </button>
                        </div>
                    </div>
                    <div className="product-page-description-side">
                        <div className="product-page-description-header">
                            <h3 className="product-page-header-price">
                                Price:
                                {
                                    ` ${prod.prices[aPrice].prc}`
                                }
                            </h3>
                        </div>
                        <div className="product-page-description-cont">
                            <div className="product-page-description-sec-c">
                                <h3 className="product-page-description-addons-header">
                                    Addons
                                </h3>
                                <div className="product-page-addons-list-holder">
                                    {
                                        _.isArray(prod.addons) && !_.isEmpty(prod.addons)
                                        ? prod.addons.map((ads) => (
                                            <div className="product-page-addon-list">
                                                <div className="product-page-addon-list-icon">
                                                    <img src={pointer} alt="pointer" className="component-section-item-point-pointer"  />
                                                </div>
                                                <div className="product-page-addon-list-text">
                                                    <span className="product-page-addon-list-text-name">
                                                        {
                                                            ads.name
                                                        }
                                                    </span>
                                                    <span className="product-page-addon-list-text-value">
                                                        {
                                                            ads.price
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        ))
                                        : <></>
                                    }
                                </div>
                            </div>
                            <div className="product-page-description-sec">
                                <ComponentSection
                                    components={prod.components}
                                />
                            </div>
                            <div style={{marginTop: "18px"}} className="product-page-description-sec">
                                <SizeSection
                                    sizes={prod.sizes}
                                    active={aPrice}
                                    actFun={setAprice}
                                />
                            </div>
                            <div style={{marginTop: "18px"}} className="product-page-description-sec">
                                <FeatureSection
                                    featurs={prod.features}
                                />
                            </div>
                        </div>
                        {/* hover button */}
                        <div className="product-page-description-action-holder">
                            <button className="product-page-action-view-imaages2" type="button" onClick={()=> navigate("/")} >
                                Go Back
                            </button>
                            <button className="product-page-action-buy-now" type="button" onClick={() => setPopup(true)}>
                                Order Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="product-page-image-gallery-holder" style={photos === 'closed' ? {display : "none"} : {}}>
                <GalleryImage 
                    images={prod.images}
                    closeFun={setPhotos}
                />
            </div>
            <div className="product-page-popup-holder" style={popup ? {} : { display: "none"}}>
                <Popup
                    closeFun={setPopup}
                    dataFun={{
                        ProductName: prod.name
                    }}
                />
            </div>
        </div>
    );
}


export default ProductPage;
