import React from 'react';
import ReactPlayer from 'react-player';
import './videoSection.css';
// static
import vd from '../../images/screen.mp4';

function VideoSection() {
    return (
        <div className="video-section-home-main">
            <h2 className="video-section-home-header">
            <span style={{color: "#0D645D"}}>Experience</span> the World In a New Level
            </h2>
            <div className="video-section-home-player-holder">
                <ReactPlayer 
                    url={vd}
                    playing={true}
                    volume={0}
                    muted={true}
                    loop={true}
                />
            </div>
        </div>
    );
}

export default VideoSection;
