import React from 'react';
import './loadingEffect.css';
function LoadingEffect() {
    return (
        <div className="loading-effect-main">
            <div class="spinner-box">
                <div class="configure-border-1">  
                    <div class="configure-core"></div>
                </div>  
                <div class="configure-border-2">
                    <div class="configure-core"></div>
                </div> 
            </div>
        </div>
    );
}

export default LoadingEffect;