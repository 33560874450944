import React from 'react';
import { useNavigate } from 'react-router-dom';
import './catalogs.css';
// static
import catImg from '../../images/catalog.png'

function Catalogs() {
    const navigate = useNavigate()
    return (
        <div className="catalogs-section-main">
            <div className="catalogs-section-content-holder">
                <div className="catalogs-section-texts-action-holder">
                    <h2 className="catalogs-section-texts-header">
                        You deserve better devices
                    </h2>
                    <div className="catalogs-section-texts-disc">
                        From dynamic signage solutions ideal for enhancing shopfronts and captivating advertisements that demand attention,
                        to intuitive self-ordering kiosks revolutionizing customer service, we've curated a selection that embodies innovation and performance.
                    </div>
                    <div className="catalogs-section-action-holder">
                        <button className="catalogs-section-action-button" type="button" onClick={()=> navigate("/products/list")}>
                            View All Products
                        </button>
                    </div>
                </div>
                <img src={catImg} className="catalog-section-image" alt="products all in one" />
            </div>
        </div>
    );
}

export default Catalogs;
