import React from 'react';
import { useNavigate } from 'react-router-dom';
import './products.css';
// products
import { productsList } from '../../ProductList';
// components
import ProductRow from './product-row/ProductRow';
// statics
import loneScreen from '../../images/screen-alone.png';
import standScreen from '../../images/screen-stand.png';
import touch from '../../images/touch.svg';
import pen from '../../images/pen.svg';
import im from '../../images/image.svg';
import split from '../../images/split.svg';
import qr from '../../images/qr_code.svg';
import cat from '../../images/category.svg';
import stolen from '../../images/stolen.png';
// stand screen  features
import idea from '../../images/idea.svg';
import stylus from '../../images/stylus.svg';
import filez from '../../images/file_icon.svg';
import cast from '../../images/cast.svg';
import came from '../../images/camera.svg';
import chip from '../../images/chip.svg';

function Products() {
    const navigate = useNavigate();
    const openProct = (proId) => {
        navigate("/product/"+proId)
    }
    return (
        <div className="products-main-section">
            <div className="products-section-header-disc-part">
                <h2 className="products-section-header be-vietnam-pro-black">
                <span style={{color: "#0B655D"}}>Our</span> <br /> Whiteboards
                </h2>
                <div className="products-section-disc">
                    Explore our curated collection of innovative solutions, meticulously designed to enhance
                    productivity and elevate professionalism.
                </div>
            </div>


            <h1 className="product-six-features-discription-header-mobile">
                Say <span style={{color: "#0D645D"}}>Hello</span> to MT Whiteboard
            </h1>


            <div className="product-six-features-pc">
                <h1 className="product-six-features-discription-header-pc">
                    Say <span style={{color: "#0D645D"}}>Hello</span> to <br/> MT Whiteboard
                </h1>
                <div className="product-six-features-splitter-features">
                    <div className="product-six-features-splitter-left">
                        <div className="product-six-features-splitter-holder">
                            {
                                list_product(touch, "20 Point IR Touch", `
                                    Supports multi-users co-operating simultaneously via fingers or stylus,
                                    Super glide surface offers highly precise and user-friendly writing experience
                                `)
                            }
                            {
                                list_product(pen, "Dual Pen Selection", `
                                    Different colors, pen size and transparency of writing can be selected between thick pen and thin pen.
                                `)
                            }
                            {
                                list_product(im, "Insert Pictures And Shapes", `
                                    You can zoom in and out of the picture at will, drag and rotate, gesture is easy to operate, intuitive display of your content.
                                `)
                            }
                        </div>
                    </div>
                    <img src={loneScreen} className="product-six-feature-image-pc" alt="white board" />
                    <div className="product-six-features-splitter-right">
                        <div className="product-six-features-splitter-holder">
                            {
                                list_product(split, "Split Screen", `
                                    Click " Split Screen” to enter dual screen, user can choose pen thickness, color for writing or drawing.
                                `)
                            }
                            {
                                list_product(qr, "Share With QR-Code", `
                                    All pages were created on Whiteboard can be generated QR code & scanned QR code by smart phone to take away
                                `)
                            }
                            {
                                list_product(cat, "Others", `
                                Besides, there are more tools in the whiteboard, such as change background, page management, switch direction, palm erase and so on.
                                `)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="product-order-one-now-action-holder">
                <button className="product-order-one-now-action" type="button" onClick={()=> openProct('whiteboard')} >Order One Now</button>
            </div>
            <div className="product-six-features-pc-stand">
                <div className="product-six-features-splitter-features-stand">
                    <img src={standScreen} className="product-six-feature-image-pc-stand" alt="white board" />
                    <div className="product-six-features-splitter-left-stand">
                        <div className="product-six-features-splitter-holder">
                            {
                                list_product(idea, "Elengance Design", `
                                    Die-casting Process back cabinet, The ultra-narrow and elegance front frame.
                                `)
                            }
                            {
                                list_product(stylus, "Smooth Writing", `
                                    Zero bonding technology brings high proximity between fingertip and writing trail, that makes writing smoother as natural as you're writing on the paper.
                                `)
                            }
                            {
                                list_product(filez, "Annotate on any file & Share", `
                                    Support PPT, Word document, picture and video annotation from all sources. The annotation demo can be saved or scanned the QR code to take away.
                                `)
                            }
                        </div>
                    </div>
                    <div className="product-six-features-splitter-right-stand">
                        <div className="product-six-features-splitter-holder">
                            {
                                list_product(came, "Video Conference", `
                                    Built-in 13MP camera & 8-Array Microphone, make remote meeting collaboration more efficient.
                                `)
                            }
                            {
                                list_product(cast, "Wireless Screen Casting", `
                                    Users can share any external content without any cables. Using only QR codes,
                                    user can connect to the display by any device (Windows, Android, MacOS, iOS), create Multiscreen experience, and annotate on the display wirelessly.
                                `)
                            }
                            {
                                list_product(chip, "Dual System", `
                                    Empowered Android OS with full features for teaching, meeting, and business. Embedded OPS mini-PC for Windows OS, switch dual system freely on purpose.
                                `)
                            }
                        </div>
                    </div>
                </div>
                
            </div>

            {/* Mobile view features */}
            <div className="product-six-features-rows">
                <div className="product-six-features-rows-cont-holder">
                    <div className="product-six-feature-image-side">
                        <img src={loneScreen} className="product-six-feature-image" alt="white board" />
                    </div>
                    <div className="product-six-features-discription-side">
                        <div className="product-six-features-discription">
                            <div className="product-six-features-discription-list-holder">
                                {
                                    list_product(touch, "20 Point IR Touch", `
                                        Supports multi-users co-operating simultaneously via fingers or stylus,
                                        Super glide surface offers highly precise and user-friendly writing experience
                                    `)
                                }
                                {
                                    list_product(pen, "Dual Pen Selection", `
                                        Different colors, pen size and transparency of writing can be selected between thick pen and thin pen.
                                    `)
                                }
                                {
                                    list_product(im, "Insert Pictures And Shapes", `
                                        You can zoom in and out of the picture at will, drag and rotate, gesture is easy to operate, intuitive display of your content.
                                    `)
                                }
                                {
                                    list_product(split, "Split Screen", `
                                        Click " Split Screen” to enter dual screen, user can choose pen thickness, color for writing or drawing.
                                    `)
                                }
                                {
                                    list_product(qr, "Share With QR-Code", `
                                        All pages were created on Whiteboard can be generated QR code & scanned QR code by smart phone to take away
                                    `)
                                }
                                {
                                    list_product(cat, "Others", `
                                    Besides, there are more tools in the whiteboard, such as change background, page management, switch direction, palm erase and so on.
                                    `)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="products-rows-holder">
                <ProductRow
                    image={stolen}
                    header={productsList.whiteboard.name}
                    descri={productsList.whiteboard.description}
                    productId={productsList.whiteboard.id}
                    funact={openProct}
                    arrg={true}
                    colors={"cGrdient"}
                    prList={[]}
                />
            </div>
        </div>
    );
}


function list_product(icon, header, descri) {
    return (
        <div className="product-six-features-discription-list">
            <div className="product-six-features-discription-list-icon-holder">
                <img src={icon} alt="feature icon" className="product-six-features-discription-list-icon" />
            </div>
            <div className="product-six-features-discription-list-item">
                <h5>{
                    header
                }</h5>
                <div className="product-six-features-discription-list-item-div">
                    {
                        descri
                    }
                </div>
            </div>
        </div>
    )
}

export default Products;
