import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import './popup.css';
// statics
import logo from '../../images/mtb-logo.png';
import LoadingEffect from '../loading/LoadingEffect';
import axios from 'axios';

function Popup({
    closeFun,
    dataFun,
}) {
    // const keyz = process.env.REACT_APP_API_SEC
    // console.log(keyz);
    const resetBtn = useRef();
    const [sendDet, setSendDet] = useState({email: '', phone: '', name: '',  order: ''})
    const [loadState, setLoadState] = useState(false)
    const [smsShow, setsmsShow] = useState(false)
    const [errText, setErrorText] = useState("")
    const recordInput = (nam, value) => {
        const temVa = sendDet
        temVa[nam] = value
        setSendDet(temVa)
    }
    const sendEmail = async (e)=> {
        e.preventDefault();
        try {
            setErrorText("");
            setLoadState(true);
            const res = await axios.post("https://api.mtbusinessolution.co.tz/email/send", {
                "subject": "Website order for "+dataFun.ProductName,
                "name": sendDet.name,
                "email": sendDet.email,
                "phone": sendDet.phone,
                "order": sendDet.order
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Key": process.env.REACT_APP_API_KEY,
                    "Sec": process.env.REACT_APP_API_SEC,
                }
            }
            
            );
            console.log(res.data);
            setLoadState(false)
            if (typeof (res) !== "object") {
                setErrorText("Network error")
                return
            }

            if (res.status !== 200) {
                setErrorText("Server Error")
                return
            }

            if (typeof (res.data) === "object" && typeof (res.data.state) === "string" && res.data.state === "success") {
                resetBtn.current.click();
                setsmsShow(true);
                setErrorText("Your order was successfully sent. Thank you for choosing MTB Business Solution");
            } else {
                console.log(res.data);
                setErrorText("Failed to process your order. Please try again or call us on +255686540204");
            }
        } catch (error) {
            setLoadState(false)
            console.log(error);
            setErrorText("Failed to send order request due to network error")
        }
    }

    const closePopUp = () => {
        setErrorText("")
        setsmsShow(false)
        closeFun(false)
    }
    return (
        <div className="popup-main">
            <div className="popup-container">
                <button className="popup-close-button" type="button" onClick={() => closePopUp()}>
                    <span className="material-symbols-outlined">
                        close
                    </span>
                </button>
                <div className="popup-containt">
                    <div className="popup-logo-holder">
                        <img src={logo} alt="company logo" className="popup-logo-img" />
                    </div>
                    {
                        smsShow ? 
                        (
                            <div className="sms-show-success-div-holder">
                                <h3 className="popup-header-sussess">
                                    Success
                                </h3>
                                <div className="popup-success-message-div">
                                    {
                                        errText
                                    }
                                </div>
                                <div className="popup-success-button-holder">
                                    <button className="popup-success-button" type="button" onClick={() => closePopUp()}>Okay</button>
                                </div>
                            </div>
                        )
                        :
                        <div className="popup-form-holder">
                        <h3 className="popup-header">
                            Enter Your Info to Place Order
                        </h3>
                        <form className="popoup-form" onSubmit={(e)=> sendEmail(e)}>
                            <div className="popup-form-input">
                                <div className="popup-form-input-lable">
                                    Full Name
                                </div>
                                <input required type="text" onChange={(e) => recordInput("name", e.target.value)} placeholder="John Doe" className="popup-input" />
                            </div>
                            <div className="popup-form-input">
                                <div className="popup-form-input-lable">
                                    Email Address
                                </div>
                                <input required type="email" onChange={(e) => recordInput("email", e.target.value)} placeholder="name@domain.com"  className="popup-input" />
                            </div>
                            <div className="popup-form-input">
                                <div className="popup-form-input-lable">
                                    Phone Number
                                </div>
                                <input required onChange={(e) => recordInput("phone", e.target.value)} type="text" placeholder="+255xxxxxxx" className="popup-input" />
                            </div>
                            <div className="popup-form-input">
                                <div className="popup-form-input-lable">
                                    Your Order
                                </div>
                                <textarea onChange={(e) => recordInput("order", e.target.value)} required placeholder="Your order" rows={6} className="popup-text-area"></textarea>
                                {/* <input type="number" placeholder="3" /> */}
                            </div>
                            <div className="error-post-email">
                                {
                                    errText
                                }
                            </div>
                            <div className="popup-form-submit-holder">
                                <div className="popup-form-loading-holder" style={!loadState ? {display: "none"} : {}}>
                                    <LoadingEffect />
                                </div>
                                <button style={loadState ? {display: "none"} : {}} className="popup-form-submit-button" type="submit">
                                    Submit Order
                                </button>
                                <button style={{display: "none"}} ref={resetBtn} type="reset">reset</button>
                            </div>
                        </form>
                    </div>
                    }
                    
                </div>
            </div>
        </div>
    );
}

Popup.propTypes = {
    closeFun: PropTypes.func.isRequired,
    dataFun: PropTypes.objectOf(PropTypes.string)
};

export default Popup;
