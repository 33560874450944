/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';
import axios from 'axios';
import './footer.css';
import { Link } from 'react-router-dom';
// static stuffs
import logo from '../../images/mtb-logo.png';
import vscreen from '../../images/v-screen-small.png';
import loader from '../../images/loader.gif';
import arrow from '../../images/arrow-east.svg';

function Footer() {
    const getCurrentYear = () => {
        const now = new Date();
        return now.getFullYear();
    }
    const btId = useRef();
    const [sendDet, setSendDet] = useState()
    const [loadState, setLoadState] = useState(false)
    const sendEmail = async (e)=> {
        e.preventDefault();
        try {
            setLoadState(true);
            const res = await axios.post("https://api.mtbusinessolution.co.tz/email/demo", {
                "subject": "Product Demo Request From User",
                "email": sendDet
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Key": process.env.REACT_APP_API_KEY,
                    "Sec": process.env.REACT_APP_API_SEC,
                }
            }
            
            );
            console.log(res.data);
            setLoadState(false);
            btId.current.click();
            
        } catch (error) {
            setLoadState(false)
            console.log(error);
        }
    }
    return (
        <div className="Footer-main">
            <div className="footer-top-section-with-demo-req">
                <div className="footer-top-section-left">
                    <div className="footer-top-section-left-company-logo">
                        <div className="footer-top-section-left-company-info">
                            <div className="footer-top-section-company-name"><span style={{color:"#04A79E"}}>MT</span> Business Solutions</div>
                            <img src={logo} alt="company logo" className="company-logo-footer" />
                        </div>
                        <div className="company-slogan-text-info">
                            Bringing Technology Closer to you entails our relentless dedication to crafting innovative solutions that seamlessly integrate into your daily life
                        </div>
                    </div>
                    <div className="footer-top-section-left-footer-things">
                        <div className="footer-column">
                            <div className="footer-column-header">Social</div>
                            <div className="footer-column-row">
                                <a href="#">X.com</a>
                                <a href="#">LinkedIn</a>
                                <a href="#">Facebook</a>
                                <a href="https://www.instagram.com/mtbusinesssolutions?igsh=bnF0ZDV4ZmtpOXY%3D&utm_source=qr">Instagram</a>
                                <a href="#">Whatsapp</a>
                            </div>
                        </div>
                        <div className="footer-column">
                            <div className="footer-column-header">Company</div>
                            <div className="footer-column-row">
                                <Link to="/about/us" className="footer-internal-link" >About Us</Link>
                            </div>
                        </div>
                        <div className="footer-column mobile-footer-column">
                            <div className="footer-column-header">Our Slogan</div>
                            <div className="footer-column-info"> 
                                "Empowering Your Digital Journey: Where Innovation Meets Excellence" encapsulates our company's commitment to being a catalyst for transformative experiences in the digital realm. 
                                With "Empowering Your Digital Journey," we acknowledge the dynamic nature of technology's role in shaping modern interactions and endeavors.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-top-section-right">
                    <div className="footer-right-demo-word">DEMO</div>
                    <h2 className="footer-right-request-demo-word">Request a <br/>Demo</h2>
                    <div className="footer-right-button-input-holder">
                        <form onSubmit={(e)=> sendEmail(e)}>
                            <input type="email" onChange={(e) => setSendDet(e.target.value)} required placeholder="Enter your email" className="demo-request-input-footer" />
                            <button  style={!loadState ? {} : { display: "none" }} className="footer-button-request-submit" type="submit" >
                                <img src={arrow} className="arrow-footer-right-button-icon" alt="icon arrow" />
                            </button>
                            <button type="reset" ref={btId} style={{display: "none"}}>reset</button>
                            <div className="footer-loader-request-submit" style={loadState ? {} : { display: "none" }}>
                                <img src={loader} className="loader-image-emails" alt="loader" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="footer-bottom-section">
                <div className="footer-bottom-text-row">
                    <div className="address-footer-lower-row">
                        PSSSF MILLENNIUM TOWERS PHASE II 20th Floor WING A, DAR ES SALAAM, TANZANIA
                    </div>
                    <div className="copyright-footer">
                        <span className="material-symbols-outlined">
                            copyright
                        </span>
                        <div className="footer-bottom-copyright-year">
                            {
                                getCurrentYear()+" MTB Company LTD"
                            }
                        </div>
                    </div>
                </div>
                <div className="footer-image-holder">
                    <img src={vscreen} className="footer-image-bottom" alt="digital signage" />
                </div>
            </div>
        </div>
    );
}

export default Footer;
