import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './homePage.css';
// static content
import standScreen from '../images/screen-stand.png';
import logo from '../images/mtb-logo.png';
import buniness from '../images/business.png';
import education from '../images/students.avif'
// components
import Mount from './mounts/Mount';
import Products from './products/Products';
import VideoSection from './video/VideoSection';
import Catalogs from './deserve/Catalogs';
import Footer from './footer/Footer';

function HomePage() {
    const navigate = useNavigate()

    return (
        <div className="HomePageMain">
            <div className="Hero-section-home">
                <div className="Hero-section-Image-Canvas">
                    <div className="hero-section-mobile-text-section">
                        <div className="hero-section-mobile-logo-holder">
                            <img src={logo} alt="logo" className="hero-section-mobile-logo" />
                        </div>
                        <div className="hero-section-mobile-text-descript">
                            <h2 className="hero-section-mobile-text-descript-h2">
                                MT Smart Board
                            </h2>
                            <div className="hero-section-mobile-text-descript-div">
                                6 in 1 Smart Whiteboard
                            </div>
                            <button onClick={()=> navigate("/product/whiteboard")} type="button" className="hero-section-mobile-action-order-now">
                                Order One Now
                            </button>
                        </div>
                    </div>
                    <img src={standScreen} className="stand-screen-image-hero" alt="stand screen hero" />
                    <div className="Areas-applications-home-image-hero">
                        <div className="application-text-hero">
                        <span style={{color: "#04A79E", fontSize: "31px"}}>Experience</span> in Diverse Scenarios
                        </div>
                        <div className="application-images-hero-holder">
                            <div className="application-images-hero-div" style={{marginRight: '10px'}}>
                                <Link to="/education">
                                    <img className="application-images-hero" src={education} alt="students in the class" />
                                </Link>
                                <h3 className="application-text-nhero">Education</h3>
                            </div>
                            <div className="application-images-hero-div">
                                <Link to="business">
                                    <img className="application-images-hero" src={buniness} alt="students in the class" />
                                </Link>
                                <h3 className="application-text-nhero">Business</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Text-action-Hero-Home-section">
                    <div className="Text-action-Hero-Home">
                        <div className="logo-product-name-hero-home">
                            <img src={logo} className="logo-hero-home" alt="hero company logo" />
                            <div className="product-name-hero-home">MT Smart Board</div>
                        </div>
                        <div className="Text-description-hero-home">
                            6 in 1 Smart Whiteboard
                        </div>
                        <div className="Actions-holder-hero-home">
                            <button className="action-button-hero-home business-action-button-hero-home" type="button" onClick={()=> navigate("/product/whiteboard")}  >
                                Order One Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Home-section">
                <Mount />
            </div>
            <div className="Home-section">
                <VideoSection />
            </div>
            <div className="Home-section">
                <Products />
            </div>
            <div className="Home-section">
                <Catalogs />
            </div>
            <div className="Home-section">
                <Footer />
            </div>
        </div>
    );
}

export default HomePage;
