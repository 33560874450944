import React from 'react';
import PropTypes from 'prop-types';
import './galleryImage.css';
import ImageGallery from "react-image-gallery";

function GalleryImage({images, closeFun}) {
    return (
        <div className="product-gallery-main">
            <div className="product-gallery-close-button-holder">
                <button className="product-gallery-close-button" type="button" onClick={() => closeFun('closed')}>
                    <div className="product-gallery-close-button-icon-div">
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </div>
                    Close
                </button>
            </div>
            <div className="product-gallery-container-holder">
                <ImageGallery items={images} />
            </div>
        </div>
    );
}

GalleryImage.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object),
    closeFun: PropTypes.func.isRequired
};

export default GalleryImage;
