import React from 'react';
import { useNavigate } from 'react-router-dom';
import './educationMain.css';
// static
import shule from '../images/shule.png';
import shule_logo from '../images/school_toto.png';
import EducationRow from './row_education/EducationRow';
// classes icons
import logox from '../images/class1.webp';
// components
import Footer from '../Home/footer/Footer';

function EducationMain() {
    const navigate = useNavigate();
    return (
        <div className="education-main">
            <div className="education-hero-section">
                <div className="education-hero-header-holder">
                    <h1 className="education-hero-header-main">
                        Education
                    </h1>
                    <div className="education-hero-header-text-description">
                        Transforming Classrooms with Innovative MT Board
                    </div>
                </div>
                <div className="education-hero-image-holder">
                    <img src={shule_logo} alt="education stand" className="education-hero-image" />
                </div>
            </div>
            <div className="education-small-text-description-holder">
                <div className="education-small-text-description">
                    <span style={{color: "#04A79E", fontWeight: "bold"}}>Engage. Educate. Empower.</span> MT whiteboard offers educators and students alike the opportunity to
                    foster a more interactive, engaging, and productive learning environment.
                </div>
                <div className="education-small-text-description-action-holder">
                    <button className="education-small-text-description-action" type="button" onClick={() => navigate("/product/whiteboard")}>
                        Get One Now
                    </button>
                </div>
            </div>
            <div className="education-details-rows-holder">
                <EducationRow
                    header="Transform Teaching Into a Simple and Engaging Experience"
                    logoz={logox}
                    descriptions={[
                        "Embrace a digital approach to teaching and interactive learning. Import various materials into the white boards, annotate and save classroom lessons for future use.",
                        "Use multimedia features to enrich lesson contents whether it's for art, science, literature, or mathematics, MT Boards tools enhance learning experience. Diagrams, graphs, and illustrations can be effortlessly integrated into lessons, helping students visualize complex concept",
                        "Collaboration Made Easy,  share ideas, annotate directly on shared documents, and contribute to collective learning goals from any device《Windows, iOS, Mac or Android OS》"
                    ]}
                    image={shule}
                />
            </div>
            <div className="education-details-final-info">
                <h2 className="education-details-final-header">
                    Sustainability and Cost-Effective MT Boards
                </h2>
                <div className="education-details-final-disc">
                    Reduce paper waste and embrace sustainability with our eco-friendly  MT Board. Durable and designed for long-term use, our whiteboards minimize the need for disposable teaching materials, offering a cost-effective and environmentally friendly alternative for educational institutions.
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
}

export default EducationMain;
