import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../education/educationMain.css';
// static
import shule from '../images/business.png';
import shule_logo from '../images/busi_img.png';
import logox from '../images/class2.webp';
// components
import Footer from '../Home/footer/Footer';
import EducationRow from '../education/row_education/EducationRow';

function BusinessMain() {
    const navigate = useNavigate();
    return (
        <div className="education-main">
            <div className="education-hero-section">
                <div className="education-hero-header-holder">
                    <h1 className="education-hero-header-main">
                        Business
                    </h1>
                    <div className="education-hero-header-text-description">
                        Elevate Your Business Meetings with Advanced MT Smart Boards
                    </div>
                </div>
                <div className="education-hero-image-holder">
                    <img src={shule_logo} alt="education stand" className="education-hero-image" />
                </div>
            </div>
            <div className="education-small-text-description-holder">
                <div className="education-small-text-description">
                    <span style={{color: "#04A79E", fontWeight: "bold"}}>Innovate. Collaborate. Succeed.</span> Optimize your  company performance results by adopting  MT Smart Board Today.
                </div>
                <div className="education-small-text-description-action-holder">
                    <button className="education-small-text-description-action" type="button" onClick={() => navigate("/product/whiteboard")}>
                        Get One Now
                    </button>
                </div>
            </div>
            <div className="education-details-rows-holder">
                <EducationRow
                    header="Elevate Your Business Meetings with Advanced MT Smart Boards"
                    logoz={logox}
                    descriptions={[
                        "Brainstorm ideas and strategies using MT Smart Board that offers unlimited space dynamic canvas for capturing ideas, feedback, and strategies in real-time. Visualize concepts, save and share files wirelessly making decisions more effectively.",
                        "Remote Collaboration from anywhere in the world. The Built-in 13MP camera & 8-Array microphone in MT Smart Board enable remote participants to view, contribute to, and interact with the content in real-time, ensuring seamless collaboration regardless of location.",
                        "Elevate your presentations and training sessions using MT Smart Board features such as multimedia elements, graphs, and dynamic illustrations, ensuring that your message is communicated clearly and memorably."
                    ]}
                    image={shule}
                />
            </div>
            <div style={{marginTop: "30px"}}>
                <Footer />
            </div>
        </div>
    );
}

export default BusinessMain;
