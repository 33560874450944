import React from 'react';
import './componetSection.css';
import pointer from '../../images/pointer-scan.svg';

function ComponentSection({ components }) {
    return (
        <div className="component-section-main">
            <h3 className="component-section-header">Components</h3>
            <div className="component-section-list-holder">
                {
                    components.map((vals) => (
                        listItem(vals)
                    ))
                }
            </div>
        </div>
    );
}


const listItem = (nameIt) => {
    return (
        <div className="componet-section-list-item">
            <div className="component-section-item-point">
                <img src={pointer} alt="pointer" className="component-section-item-point-pointer"  />
            </div>
            <div className="component-section-item-name">
                {
                    nameIt
                }
            </div>
        </div>
    )
}


export default ComponentSection;
