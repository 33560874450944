import whiteboard1 from './images/screen-stand.png';
import whiteboadnostand from './images/wall-mounted.png';
import miracast from './images/miracast.png';
import whitefeatures from './images/features.png';
import white_ports from './images/white-ports.png';
import white_power from './images/white-power.png';

// product base
import indoorSignage from './images/indoor-signage.png';
import conference from './images/conference.png';
import selfPay from './images/self-order.png';
import kiosk from './images/kiosk.png';
import viWall from './images/video-wall.png';
import vstand from './images/v-screen-small.png';
import dsides from './images/double-side.png';
import barscreen from './images/bar-sider.png';



export const productsList = {
    whiteboard: {
        name: 'MT Smart Board',
        id: 'whiteboard',
        description: `Revolutionize collaboration with our Digital 
        Whiteboard: Windows/Android compatible, pen input, screen cast, split screen - all 
        for just $1699. Upgrade your workspace or classroom today!`,
        mainImage: whiteboard1,
        images: [
            {
                original: whiteboadnostand,
                thumbnail: whiteboadnostand,
            },
            {
                original: whiteboard1,
                thumbnail: whiteboard1,
            },
            {
                original: miracast,
                thumbnail: miracast,
            },
            {
                original: whitefeatures,
                thumbnail: whitefeatures,
            },
            {
                original: white_ports,
                thumbnail: white_ports,
            },
            {
                original: white_power,
                thumbnail: white_power,
            },
        ], 
        components: [
            'User Manual x 1',
            'Remote Control x 1',
            'Wall Mount Bracket x 1',
            'Power Cable',
            'Stylus Pen x 2',
            'Antenna x 2',
            'Wireless Cast (optional) x 1',
            'Camera (optional) x 1',
            'OPS Kit (optional) x 1',
            'Mobile Stand (optional) x 1'
        ],
        features: [
            'Dual System (Android and Windows',
            '13MP camera',
            '8 Microphones Array',
            'Wireless Screen Casting',
            'Annotate on any file & Share',
            'Pen Input',
            '20 Point IR Touch',
            'Dual Pen Selection',
            'Split Screen',
            'Share With QR-Code',
            '4 GB RAM',
            '32 GB ROM',
            'Wifi',
            'Refresh rate 60HZ',
            'NFC',
            '3 USB ports',
            '2 HDMI ports',
            'TFCARD',
            '2 RJ45 Ports',
            'COAX'
        ],
        addons: [
            {
                name: "Mobile Stand",
                price: "$59.00"
            },
            {
                name: "Miracast",
                price: "$43.00"
            }
        ],
        prices: [
            {
                nm: '65"',
                prc: '$1699.00'
            },
            {
                nm: '75"',
                prc: '$1999.00'
            },
            {
                nm: '86"',
                prc: '$2999.00'
            }
        ],
        sizes: [
            '65"',
            '75"',
            '86"'
        ]
    }
}


export const productList2 = [
    {
        name: 'Indoor Floor Standing Digital Signage',
        id: 'Indoor-Floor-Standing',
        image: indoorSignage,
        features: [
            'Screen Sizes: 43", 49", 55", and 65"',
            'Resolution: 1920 x 1080',
            'Brightness: 400nits',
            'Contrast: 4000:1',
            'Touch: 10 points capacitive touch',
            "Operating System: Android"
        ],

    }, 
    {
        name: 'Dual Screens Smart Conference Desk',
        id: 'Dual-Screens-Smart-Conference',
        image: conference,
        features: [
            'Screens Size: 43" and 22"',
            'Touch support',
            'Operating System: Android 11 and Windows 10',
            'Can be used for meetings and speeches'
        ]
    }, 
    {
        name: 'Smart Touchpay Self-service Payment Kiosk',
        id: 'Smart-Touchpay-Self-service',
        image: selfPay,
        features: [
            'Screen Size: 27"',
            'Low power usage',
            '10 point capacitive touch screen',
            'Anti-theft lock design for public space usage',
            'Camera optional',
            'QR/Barcode Scanner',
            '58mm thermal printer'
        ]
    }, 
    {
        name: '4k Floor Standing Indoor Smart Touch Kiosk',
        id: '4k-Floor-Standing-Indoor',
        image: kiosk,
        features: [
            'Screen size: 42", 49", 55"',
            'Touch: R touch /Capacitive touch',
            'Brightness: 450nits',
            'Resolution: 2160x3840p',
            'Viewing angle: 178 °',
            'Contrast ratio: 2000:1',
            'Backlight life: 5000 hours',
            'Color: 16.7M'
        ]
    },
    {
        name: 'UHD 4K Video Wall',
        id: 'UHD-4K-Video-Wall',
        image: viWall,
        features: [
            'Screen Sizes: 55"',
            'Panel Brand: LG',
            'Brightness: 500 nits and 700 nits',
            'Resolution: 1920x1080p',
            'Bezel: 1.8mm'
        ]
    }, 
    {
        name: 'Outdoor Floor standing double sides digital signage',
        id: 'Outdoor-Floor-standing-double',
        image: vstand,
        features: [
            'Screen Sizes: 75"',
            'Brightness: 3000cd/M2( 2000cd/M2 & 2500cd/M² Option)',
            'Resolution: 3840x2160p',
            'With Double screens',
            'Work: 7x24 hours',
            'Waterproof',
            'Dustproof',
            'Fan cooling',
            'IP55 certified'
        ]
    },
    {
        name: 'Dual sides digital window display',
        id: 'Dual-sides-digital-window',
        image: dsides,
        features: [
            'Brightness: 2500 nits/700 nits',
            'Two sides screens (indoor 700nits and outdoor 2500nits',
            'Andorid System RK3288. CMS for Advertising Management',
            'Sun-Readable Supported',
            'Resolution: 1920X1080 Full HD Resolution',
            'Work: 7x24 hours',
            'Local dimming zone (optional)'
        ]
    },
    {
        name: 'Wall Mount Stretched Bar Digital Signage',
        id: 'Wall-Mount-Stretched-Bar',
        image: barscreen,
        features: [
            'Screen Size: 12" to 88"',
            'Brightness: 400nits to 1035nits',
            'Reslution: HD',
            'Individual or Splicing display'
        ]
    }
]