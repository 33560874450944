import React from 'react';
import './aboutUs.css';
// static content
import logo from '../images/mtb-logo.png';
import products from '../images/catalog.png';
import team from '../images/our-team.png';
import Footer from '../Home/footer/Footer';
import contact from '../images/contact-us.png'

function AboutUs() {
    return (
        <div className="about-us-main">
            <div className="about-us-content-holder">
                <div className="about-us-hero-section">
                    <div className="about-us-logo-holder">
                        <img src={logo} className="about-us-company-logo" alt="company logo" />
                    </div>
                    <div className="about-us-company-history-mission-texts-holder">
                        <h1 className="about-us-company-history-header">Our Company</h1>
                        <div className="about-us-company-history-discription">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil, maxime. Maiores ipsum illo rem laudantium nulla nostrum eius ullam cupiditate ex enim. Eum eligendi maiores optio dolor veritatis sapiente laboriosam.</div>
                    </div>
                </div>
                <div className="about-us-zigzag-content-holder">
                    <div className="about-us-zigzag-row">
                        <div className="about-us-zigzag-row-image-side">
                            <div className="about-us-zigzag-row-image-holder">
                                <img src={team} alt="our team" className="about-us-zigzag-row-image" />
                            </div>
                        </div>
                        <div className="about-us-zigzag-row-text-side">
                            <div className="about-us-zigzag-row-text-holder">
                                <h2 className="about-us-zigzag-row-text-header">
                                    Our Team
                                </h2>
                                <div className="about-us-zigzag-row-text-description">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil, maxime. Maiores ipsum illo rem laudantium nulla nostrum eius ullam cupiditate ex enim.
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* our product section */}
                    <div className="about-us-zigzag-row" style={{flexDirection : "row-reverse"}}>
                        <div className="about-us-zigzag-row-image-side">
                            <div className="about-us-zigzag-row-image-holder bg2">
                                <img src={products} alt="our products" className="about-us-zigzag-row-image" />
                            </div>
                        </div>
                        <div className="about-us-zigzag-row-text-side">
                            <div className="about-us-zigzag-row-text-holder">
                                <h2 className="about-us-zigzag-row-text-header">
                                    Our Products
                                </h2>
                                <div className="about-us-zigzag-row-text-description">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil, maxime. Maiores ipsum illo rem laudantium nulla nostrum eius ullam cupiditate ex enim.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-us-contact-section">
                    <h2 className="about-us-contact-us-header">
                        Contact Us
                    </h2>
                    <div className="about-us-contact-box">
                        <div className="about-us-cantact-description">Whether via email, phone call, WhatsApp, or traditional SMS, we're here for you whenever you choose to connect.</div>
                        <div className="about-us-contact-us-list-holder">
                            <div className="contact-us-channel-about-us">
                                China: +867854296307
                            </div>
                            <div className="contact-us-channel-about-us">
                                Dubai: +971 561321836
                            </div>
                            <div className="contact-us-channel-about-us">
                                TZ: +255686540204 or +255678406642
                            </div>
                            <div className="contact-us-channel-about-us">
                                Email: tmwita74@gmail.com
                            </div>
                        </div>
                    </div>
                    <div className="about-us-contact-us-image-holder">
                        <img src={contact} alt="boy pointing up" className="about-us-contact-us-image" />
                    </div>
                </div>
                <div className="about-us-footer">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
