import React from 'react';
import _ from 'lodash';
import './productList.css';
// static data
import { productList2 } from '../ProductList';
import ProductRow from '../Home/products/product-row/ProductRow';

function ProductList() {
    const orderProduct = (proId) => {

    }
    let rev = true;
    return (
        <div className="product-list-main">
            <div className="product-list-content-holder">
                <div className="products-list-header-disc-part">
                    <h2 className="products-list-header be-vietnam-pro-black">
                    <span style={{color: "#0B655D"}}>Our</span> <br /> Products
                    </h2>
                    <div className="products-list-disc">
                        Explore our curated collection of innovative solutions, meticulously designed to enhance
                        productivity and elevate professionalism.
                    </div>
                </div>
                <div className="products-list-row-holder">
                    {
                        _.isArray(productList2) && !_.isEmpty(productList2)
                        ? productList2.map((prod) => {
                            if (rev) {
                                rev = false;
                            } else { rev = true; }
                            return  <ProductRow
                                image={prod.image}
                                header={prod.name}
                                descri="list"
                                productId={prod.id}
                                funact={orderProduct}
                                arrg={rev}
                                colors={"grad3"}
                                prList={prod.features}
                            />
                        })
                        : <></>
                    }
                </div>
            </div>
        </div>
    );
}

export default ProductList;
