import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import './App.css';
// components
import MainApp from './MainApp/MainApp';
import ErrorPage from './Errors/ErrorPage';
import HomePage from './Home/HomePage';
import AboutUs from './about/AboutUs';
import ProductPage from './ProductPage/ProductPage';
import ProductList from './ProductList/ProductList';
import EducationMain from './education/EducationMain';
import BusinessMain from './business/BusinessMain';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainApp />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <HomePage />
        },
        {
          path: "about/us",
          element: <AboutUs />
        }, 
        {
          path: "product/:id",
          element: <ProductPage />
        }, 
        {
          path: "products/list",
          element: <ProductList />
        },
        {
          path: "education",
          element: <EducationMain />
        },
        {
          path: "business",
          element: <BusinessMain />
        }
      ]
    }
  ])
  return (
    <RouterProvider router={router} />
  );
}

export default App;
